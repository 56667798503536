import type { AIAgentRequestConfig } from './AIAgentRequestConfig';
import type { AISummaryStreamingConfig } from './AISummaryStreamingConfigs';
import type { PostAgentPayload } from './APIModel';

export function mapToAIAgentRequest(
	aiSummaryStreamingConfig: AISummaryStreamingConfig,
	requestUrl: string,
): AIAgentRequestConfig {
	const {
		headers,
		agentNamedId,
		locale,
		agentPrompt,
		experienceId,
		product,
		summaryOutputMimetype,
		summaryStyle,
		ari,
		promptId,
		featureFlags,
		updateType,
		jsonStructured,
		outputMimetype,
		endTimeMs,
		cloudId,
	} = aiSummaryStreamingConfig;

	const payload: PostAgentPayload = {
		recipient_agent_named_id: agentNamedId,
		agent_input_prompt: agentPrompt,
		agent_input_context: {
			content_ari: ari,
			summary_style: summaryStyle,
			prompt_id: promptId,
			update_type: updateType,
			locale,
			...(summaryOutputMimetype ? { summary_output_mimetype: summaryOutputMimetype } : {}),
			...(outputMimetype ? { output_mimetype: outputMimetype } : {}),
			json_structured: jsonStructured,
			...(endTimeMs ? { end_time_ms: endTimeMs } : {}),
		},
	};

	if ('url' in aiSummaryStreamingConfig) {
		// Send URL even if ari is present as it can be used as a fallback
		const { url } = aiSummaryStreamingConfig;
		payload.agent_input_context.content_url = url;
	}

	const requestHeaders: HeadersInit = new Headers({
		'content-type': 'application/json;charset=UTF-8',
		'x-experience-id': experienceId,
		'x-product': product,
		...headers,
	});

	if (cloudId) {
		requestHeaders.set('x-cloudid', cloudId);
	}

	const requestOptions: RequestInit = {
		method: 'POST',
		headers: requestHeaders,
		body: JSON.stringify(payload),
	};

	return {
		requestUrl,
		requestOptions,
		featureFlags,
	};
}
